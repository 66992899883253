.plus {
    width: 1em;
    height: 1em;
    font-size: 22px;
    line-height: 2em;
    border: 1px solid #626262;
    border-radius: 25px;
    background: transparent;
    padding: 5px;
    margin-top: 1px;
    margin-right: 8px;
    display: block;
}
