@mixin responsive-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin responsive-down($breakpoint) {
  @media (max-width: $breakpoint - 1px) {
    @content;
  }
}

@mixin responsive-between($breakpointSmall, $breakpointBig) {
  @media (min-width: $breakpointSmall) and (max-width: $breakpointBig - 1px) {
    @content;
  }
}