@import 'src/styles/variables';
@import 'src/styles/mixins';

* {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

body {
    font-family: $main-font;
    font-size: $base-font-size;
    line-height: $base-lineheight;
}

.ant-layout {
    background: $sec-color;
}

.ant-page-header {
    padding: 16px 5px;
}

.container {
    margin: 0 auto;
    width: 100%;
}

.ant-table-wrapper {
    .align-top {
        vertical-align: top;
    }
}

.ant-layout-content {
    .container {
        .ant-space {
            flex-wrap: wrap;
        }
    }
}

.ant-menu-submenu-popup {
    .ant-menu {
        a {
            color: #000000a6;

            &:hover {
                color: #337ab7;
            }
        }

        .ant-menu-submenu:hover {
            a {
                color: #337ab7;
            }
        }

        .ant-menu-submenu-active {
            a,  .ant-menu-submenu-arrow {
                color: #337ab7;
            }
        }
    }
}