.treeBox {
  padding: 20px 0;
  min-height: 50vh;
}

.titleContent {
  display: flex;
  align-items: center;
}

.title {
  color: #000;
}

.dashboardsCount {
  padding: 0 5px;
}