@import 'src/styles/variables';

.title {
  display: flex;
  justify-content: center;
  color: $sec-color;
  align-content: center;
  margin: 5px 0;
  font-weight: bold;
  font-size: 18px;
}