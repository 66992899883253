@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
    background-color: $header-bg;
    height: 103px;
    @include responsive-down($lg) {
        height: auto;
    }

    :global {
        .ant-menu {
            background: transparent;
            line-height: 2.5em;
            margin-top: -1px;
            margin-bottom: 3px;

            .ant-menu-submenu {
                a {
                    color: #000000a6;
                }

                .ant-menu-title-content {
                    a {
                        color: #000000a6;

                        &:hover {
                            color: #337ab7;
                        }
                    }
                }
            }
        }

        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
            padding: 0 5px;
        }

        .ant-menu-item {
            a {
                color: #000000a6;
            }
        }

        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
        .ant-menu-horizontal:not(.ant-menu-dark)
            > .ant-menu-submenu:hover::after,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
        .ant-menu-horizontal:not(.ant-menu-dark)
            > .ant-menu-submenu-active::after,
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
        .ant-menu-horizontal:not(.ant-menu-dark)
            > .ant-menu-submenu-open::after,
        .ant-menu-horizontal:not(.ant-menu-dark)
            > .ant-menu-item-selected::after,
        .ant-menu-horizontal:not(.ant-menu-dark)
            > .ant-menu-submenu-selected::after {
            border-bottom: 2px solid #3f803f;
        }

        .ant-menu-item .ant-menu-item-icon + span,
        .ant-menu-submenu-title .ant-menu-item-icon + span,
        .ant-menu-item .anticon + span,
        .ant-menu-submenu-title .anticon + span {
            margin-left: 0;
        }

        .ant-menu-horizontal > .ant-menu-item:after,
        .ant-menu-horizontal > .ant-menu-submenu:after {
            left: 0;
            right: 0;
        }
    }
}

.userContent {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    white-space: nowrap;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: right;
    padding: 0 5px;

    @include responsive-down(1000px) {
        text-align: left;
        max-width: none;
    }
}

.topText {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    align-items: center;
    color: #626262;
    padding: 10px 10px 0px;
}

.horLine {
    border-bottom: 1px solid #3f803f;
    height: 1px;
    margin-top: 10px;
    width: 100%;
}

.logoContainer {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
}

.logoColumn {
    flex: 0 0 100px;
    @include responsive-down(919px) {
        margin: auto;
        flex: 0 1 auto;
    }
}

.textColumn {
    flex: 1 0 auto;
    max-width: calc(100% - 100px);
}
