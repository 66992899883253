@import 'src/styles/variables';

.footerInfo {
    font-size: 13px;
}

.footerLink {
    display: block;
}

.footerRow {
    display: flex;
}

.footerLabel {
    margin-right: 5px;
}

.restoreLink {
    margin-top: 10px;
    margin-bottom: 10px;
}

.line:last-child {
    margin-bottom: 9px;
}
