.card  {
  :global {
    .ant-card-head-title {
      white-space: normal;
    }
  }

  p {
    margin-bottom: 8px;
  }
}

.icon {
  margin-right: 5px;
  position: relative;
  top: 1px;
}