.charts {
  width: 100%;
  border: 0;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    border: 0;
  }
}