@import 'src/styles/variables';
@import 'src/styles/mixins';

:global {
    .ant-menu-overflow {
        @include responsive-down($sm) {
            flex-wrap: wrap;
        }
    }
}
