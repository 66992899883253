@import 'src/styles/variables';

.title {
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.circleIcon.circleIcon.circleIcon {
    margin-right: 5px;
    font-size: 20px;
    color: #626262;
}

:global {
    .ant-form-item-has-error.ant-form-item-has-error.ant-form-item-has-error {
        .ant-upload-error {
            border: 1px solid $validation-error;
        }
    }
}
